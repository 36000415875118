/* Neutral Color Palette (Grey Shades) */
$grey1: #ffffff;
$grey2: #f5f8fb;
$grey3: #ebf1f8;
$grey4: #dce6f1;
$grey5: #c5d6e7;
$grey6: #a1b2c2;
$grey7: #84929f;
$grey8: #68737d;
$grey9: #292929;
$grey10: #fff;

/* Daybreak Blue */
$blue1: #e6f7ff;
$blue2: #bae7ff;
$blue3: #91d5ff;
$blue4: #69c0ff;
$blue5: #40a9ff;
$blue6: #1890ff;
$blue7: #096dd9;
$blue8: #0050b3;
$blue9: #003a8c;
$blue10: #152535;

/* Dust Red */
$red1: #ffe0dd;
$red2: #ffc1bc;
$red3: #ffa19c;
$red4: #ff7e7e;
$red5: #ff5760;
$red6: #ff1744; // Rejection Button
$red7: #d11e39;
$red8: #a4202f;
$red9: #7a1e25;
$red10: #51191b;
$red11: #fff1f0;
$red12: #ff4d4f;

/* Volcano */
$volcano1: #fff2e8;
$volcano2: #ffd8bf;
$volcano3: #ffbb96;
$volcano4: #ff9c6e;
$volcano5: #ff7a45;
$volcano6: #fa541c;
$volcano7: #d4380d;
$volcano8: #ad2102;
$volcano9: #871400;
$volcano10: #610b00;

/* Sunset Orange */
$orange1: #fff7e6;
$orange2: #ffe7ba;
$orange3: #ffd591;
$orange4: #ffc069;
$orange5: #ffa940;
$orange6: #fa8c16;
$orange7: #d46b08;
$orange8: #ad4e00;
$orange9: #873800;
$orange10: #612500;

/* Calendula Gold */
$gold1: #fffbe6;
$gold2: #fff1b8;
$gold3: #ffe58f;
$gold4: #ffd666;
$gold5: #ffc53d;
$gold6: #faad14;
$gold7: #d48806;
$gold8: #ad6800;
$gold9: #874d00;
$gold10: #613400;

/* Sunrise Yellow */
$yellow1: #feffe6;
$yellow2: #ffffb8;
$yellow3: #fffb8f;
$yellow4: #fff566;
$yellow5: #ffec3d;
$yellow6: #f5d505;
$yellow7: #d4b106;
$yellow8: #ad8b00;
$yellow9: #876800;
$yellow10: #614700;

/* Lime */
$lime1: #fcffe6;
$lime2: #f4ffb8;
$lime3: #eaff8f;
$lime4: #d3f261;
$lime5: #bae637;
$lime6: #a0d911;
$lime7: #7cb305;
$lime8: #5b8c00;
$lime9: #3f6600;
$lime10: #254000;

/* Polar Green */
$green1: #e4f9ef;
$green2: #c8f2e0;
$green3: #abebd0;
$green4: #8ce4c1;
$green5: #6adcb2;
$green6: #3bd4a3;
$green7: #36ad86;
$green8: #2f886a;
$green9: #28654f;
$green10: #1f4335;

/* Cyan */
$cyan1: #e6fffb;
$cyan2: #b5f5ec;
$cyan3: #87e8de;
$cyan4: #5cdbd3;
$cyan5: #36cfc9;
$cyan6: #13c2c2; // Approval Button
$cyan7: #08979c;
$cyan8: #006d75;
$cyan9: #00474f;
$cyan10: #002329;

/* Geek Blue */
$geekblue1: #f0f5ff;
$geekblue2: #d6e4ff;
$geekblue3: #adc6ff;
$geekblue4: #85a5ff;
$geekblue5: #597ef7;
$geekblue6: #2f54eb;
$geekblue7: #1d39c4;
$geekblue8: #10239e;
$geekblue9: #061178;
$geekblue10: #030852;

/* Golden Purple */
$purple1: #eee1ff;
$purple2: #dbc3ff;
$purple3: #c8a6ff;
$purple4: #b288ff;
$purple5: #996bff;
$purple6: #7c4dff;
$purple7: #6841d0;
$purple8: #5435a2;
$purple9: #412a77;
$purple10: #2e1f4f;

/* Magenta */
$magenta1: #fff0f6;
$magenta2: #ffd6e7;
$magenta3: #ffadd2;
$magenta4: #ff85c0;
$magenta5: #f759ab;
$magenta6: #eb2f96;
$magenta7: #c41d7f;
$magenta8: #9e1068;
$magenta9: #780650;
$magenta10: #520339;

// Gutters (Padding/Margin Unit)
$gutter: 24px;

// border-radious
$borderRadious: 4px;

// Color Palette
$primaryColor: $blue6;
$linkColor: $blue6;
$successColor: $green6;
$warningColor: $gold6;
$errorColor: $red6;
$headerColor: #4e555c;
$secondaryTextColor: $grey7;
$disabledColor: $grey6;
$borderRadius: 3px;
$borderColor: $grey5;
// $borderColor: #d9d9d9;
$boxShadow: 0px 4px 8px rgba(0, 0, 0, 0.15);

// Font Color
$primaryTextColor: $grey8;
$secondaryTextColor: $grey7;
$darkTextColor: $grey9; // Title

// Background Color
$pageBackgroundColor: $grey3; //Background

// Tags
$tagBackgroundColor: #e6f7ff;
$tagTextColor: $grey7;

// Dropzone
$dropzoneBackgroundColor: $grey2;
$dropzoneTextColor: #69c0ff;

// Sidebar Menu Item Text Color
// $sidebarBackgroundColor: #3f0e40;
$sidebarBackgroundColor: #152535;
$sidebarTextColor: $grey2;

//Dividers
$dividersColor: $grey4;

//Table Header
$tableHeaderColor: $grey2;
$tableBodyColor: $grey1;
$tableBorderColor: $grey3;

// Tabs
// Active Tab Text Color
$activeTabTextColor: $grey9;

// Footer background
$footerBackgroundColor: $grey2;

// Nested Table Background Color
$nestedTableBackgroundColor: rgba(#333, 0.07);

//status
$DRAFTD: #ffa500;
$DRAFTDLine: $yellow2;
$DRAFTDBg: $yellow2;

$PENDNG: $magenta6;
$PENDNGLine: $magenta1;
$PENDNGBg: $magenta1;

$APPRVD: $cyan7;
$APPRVDLine: $cyan1;
$APPRVDBg: $cyan1;

$REJCTD: $red6;
$REJCTDLine: $red1;
$REJCTDBg: $red1;

$WITHDR: $grey8;
$WITHDRLine: $grey3;
$WITHDRBg: $grey3;

$UNDPRS: #800080;
$UNDPRSLine: $purple2;
$UNDPRSBg: $purple2;

$SETTLD: #0000ff;
$SETTLDLine: $geekblue2;
$SETTLDBg: $geekblue2;

$STALED: $grey8;
$STALEDLine: $cyan1;
$STALEDBg: $cyan1;

$EXPRED: $red12;
$EXPREDLine: $red11;
$EXPREDBg: $red11;

// Form Field Heights
$formFieldHeight: 40px;
$formFieldDisableBGColor: #f5f5f5;
$formFieldDisableColor: $grey6;
